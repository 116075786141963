<template>
    <div>
        <v-overlay :value="loading" :opacity="0.35" color="#d3d3d3">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'" class="d-flex align-center">
                                <div style="word-break: keep-all;">
                                    <h5>Head to head results over time</h5>
                                </div>
                            </v-col>
                            <v-spacer />
                            <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                <div>
                                    <v-select v-if="$vuetify.breakpoint.xsOnly" :items="getTeamsHurlingSort"
                                        v-model="overallTeam" @change="updateOverallResults" label="Team"
                                        item-text="name" return-object outlined hide-details hide-no-data></v-select>
                                    <v-combobox v-else :items="getTeamsHurlingSort" v-model="overallTeam"
                                        @change="updateOverallResults" label="Team" item-text="name" return-object
                                        outlined hide-details hide-no-data></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                <div>
                                    <v-select v-if="$vuetify.breakpoint.xsOnly" :disabled="!overallTeam"
                                        :items="overallOppositionOption" v-model="overallOpposition"
                                        @change="updateOverallFilter" multiple label="Opposition" item-text="name"
                                        return-object outlined hide-details hide-no-data
                                        style="text-overflow: ellipsis; white-space: nowrap;">
                                        <template #selection="{ item }">
                                            <span v-if="overallOpposition.length === 1">{{ item.name }}</span>
                                            <span v-else-if="item.name == overallOpposition[0].name">{{
                                                overallOpposition.length }} teams</span>
                                        </template>
                                    </v-select>
                                    <v-combobox v-else :disabled="!overallTeam" :items="overallOppositionOption"
                                        v-model="overallOpposition" @change="updateOverallFilter" multiple
                                        label="Opposition" item-text="name" return-object outlined hide-details
                                        hide-no-data style="text-overflow: ellipsis; white-space: nowrap;">
                                        <template #selection="{ item }">
                                            <span v-if="overallOpposition.length === 1">{{ item.name }}</span>
                                            <span v-else-if="item.name == overallOpposition[0].name">{{
                                                overallOpposition.length }} teams</span>
                                        </template>
                                    </v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                <div>
                                    <v-select v-if="$vuetify.breakpoint.xsOnly" :items="overallStageOptions"
                                        @change="overallStageFilterChanged" v-model="overallStageFilter"
                                        item-text="label" item-value="value" label="Stage" outlined hide-details
                                        hide-no-data></v-select>
                                    <v-combobox v-else :items="overallStageOptions" @change="overallStageFilterChanged"
                                        v-model="overallStageFilter" item-text="label" item-value="value" label="Stage"
                                        outlined hide-details hide-no-data></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'" class="d-flex align-center">
                                <div>
                                    <v-btn class="grey" :class="this.$vuetify.theme.dark ? 'darken-2' : 'lighten-2'"
                                        @click="showOverallFilters = !showOverallFilters">{{ showOverallFilters ? 'Less'
                                            : 'More' }}
                                        <v-icon class="ml-1">{{ showOverallFilters ? 'mdi-arrow-up-drop-circle-outline'
                                            :
                                            'mdi-arrow-down-drop-circle-outline' }}</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <transition name="fade">
                            <v-row v-if="showOverallFilters"
                                :class="this.$vuetify.breakpoint.xs ? 'settings_height_mobile' : 'settings_height'">
                                <v-spacer />
                                <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                    <div>
                                        <v-select v-if="$vuetify.breakpoint.xsOnly" :items="overallYearFromFilters"
                                            v-model="overallYearFromFilter" @change="overallYearFromFilterChanged"
                                            label="Year from" outlined hide-details hide-no-data></v-select>
                                        <v-combobox v-else :items="overallYearFromFilters"
                                            v-model="overallYearFromFilter" @change="overallYearFromFilterChanged"
                                            label="Year from" outlined hide-details hide-no-data></v-combobox>
                                    </div>
                                </v-col>
                                <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                    <div>
                                        <v-select v-if="$vuetify.breakpoint.xsOnly" :items="overallYearToFilters"
                                            v-model="overallYearToFilter" @change="overallYearToFilterChanged"
                                            label="Year to" outlined hide-details hide-no-data></v-select>
                                        <v-combobox v-else :items="overallYearToFilters" v-model="overallYearToFilter"
                                            @change="overallYearToFilterChanged" label="Year to" outlined hide-details
                                            hide-no-data></v-combobox>
                                    </div>
                                </v-col>
                                <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                    <div>
                                        <v-select v-if="$vuetify.breakpoint.xsOnly" :items="overallHomeAwayOptions"
                                            v-model="overallHomeFilter" @change="overallHomeFilterChanged"
                                            item-text="label" return-object label="Location" outlined hide-details
                                            hide-no-data></v-select>
                                        <v-combobox v-else :items="overallHomeAwayOptions" v-model="overallHomeFilter"
                                            @change="overallHomeFilterChanged" item-text="label" return-object
                                            label="Location" outlined hide-details hide-no-data></v-combobox>
                                    </div>
                                </v-col>
                                <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                    <div>
                                        <v-select v-if="$vuetify.breakpoint.xsOnly" :items="getOverallFilteredStadiums"
                                            @change="overallStadiumFilterChanged" v-model="overallStadiumFilter"
                                            item-text="label" item-value="value" label="Stadium" outlined hide-details
                                            hide-no-data></v-select>
                                        <v-combobox v-else :items="getOverallFilteredStadiums"
                                            @change="overallStadiumFilterChanged" v-model="overallStadiumFilter"
                                            item-text="label" item-value="value" label="Stadium" outlined hide-details
                                            hide-no-data></v-combobox>
                                    </div>
                                </v-col>
                                <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                    <div>
                                        <v-select v-if="$vuetify.breakpoint.xsOnly" :items="getRoundOptions" @change="overallRoundFilterChanged"
                                            v-model="overallRoundFilter" item-text="label" item-value="value"
                                            label="Round" outlined hide-details hide-no-data clearable></v-select>
                                        <v-combobox v-else :items="getRoundOptions" @change="overallRoundFilterChanged"
                                            v-model="overallRoundFilter" item-text="label" item-value="value"
                                            label="Round" outlined hide-details hide-no-data clearable></v-combobox>
                                    </div>
                                </v-col>
                            </v-row>
                        </transition>
                        <div v-if="overallTeam == null || overallOpposition.length == 0" class="caption">Select at least
                            two teams to populate
                            graph</div>
                    </v-card-title>
                    <v-card-text>
                        <div id="pie-chart-share">
                            <transition name="fade">
                                <div id="head-to-pie-chart" v-if="overallTeam != null && overallOpposition.length > 0"
                                    class="chartBackground"
                                    :class="this.$vuetify.breakpoint.xs ? 'chart_height_mobile' : 'chart_height'">
                                    <apexchart type="scatter" height="400" :options="overallScatterChartOptions"
                                        :series="overallScatterSeries"></apexchart>
                                </div>
                            </transition>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex/dist/vuex.common.js';
import StatsCard from '@/components/card/statsCard/StatsCard'
export default {
    name: 'WinDistribution',
    components: {
        StatsCard
    },
    async mounted() {
        if (this.getTeamsHurlingSort.length > 0) {
            this.loading = false;
        } else {
            await this.fetchTeamsBySport(this.$router.currentRoute.meta.sport.toUpperCase());
        }
        this.populateQueryParams();
    },
    data() {
        return {
            loading: true,
            overallTeam: null,
            overallOpposition: [],
            overallYearFromFilter: 'All',
            overallYearToFilter: 'All',
            overallStadiumFilter: 'All',
            overallStageFilter: 'All',
            overallHomeFilter: null,
            showOverallFilters: false,
            overallRoundFilter: null
        }
    },
    computed: {
        ...mapGetters(["getTeamsHurlingSort", "getResults", "getOverallFilteredStadiums", "getFilteredHurlingResults", "getFilteredOverallHurlingResults",
            "getOverallResults", "getRoundOptions", "getOverallOppositionFilter"]),
        overallOppositionOption() {
            let choices = this.$store.getters['getTeamsHurlingSort'].map(it => it);
            if (this.overallTeam) {
                choices = choices.filter(it => it.id != this.overallTeam.id)
            }
            if (this.overallOpposition && this.overallOpposition.length > 0) {
                choices = choices.filter(it => it.id != this.overallOpposition.id)
            }
            // TODO - not working
            choices.unshift({ name: "Connacht", grouper: true });
            choices.unshift({ name: "Ulster", grouper: true });
            choices.unshift({ name: "Leinster", grouper: true });
            choices.unshift({ name: "Munster", grouper: true });
            return choices;
        },
        overallHomeAwayOptions() {
            let teamOptions = [{ "label": 'All', "value": null }, { "label": "Neutral", "value": -1 }];
            if (this.overallTeam) {
                teamOptions.push({ "label": this.overallTeam.name + ' home', "value": this.overallTeam.id });
                teamOptions.push({ "label": this.overallTeam.name + ' away', "value": this.overallTeam.id * -1 });
            }
            return teamOptions;
        },
        overallStageOptions() {
            let stages = ['All', 'Munster', 'Leinster', 'All Ireland'];
            return stages;
        },
        overallScatterChartOptions() {
            let colours = [];
            if (this.overallTeam) {
                colours = [this.overallTeam.colour];
            }
            for (let i = 0; i < this.getOverallOppositionFilter.length; i++) {
                if (this.getOverallOppositionFilter[i].grouper) continue;
                let teamObj = this.getTeamsHurlingSort.find(it => it.id == this.getOverallOppositionFilter[i].id);
                colours.push(teamObj.colour);
            }
            colours.push('#515151');
            return {
                title: {
                    text: 'Win Distribution',
                    align: 'left',
                    style: {
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: '#666'
                    }
                },
                chart: {
                    type: 'scatter',
                    zoom: {
                        enabled: false
                    }
                },
                colors: colours,
                markers: {
                    strokeWidth: 1,
                    strokeColor: '#000000'
                },
                xaxis: {
                    title: {
                        text: 'Year'
                    },
                    type: 'numeric',
                    // tickAmount: this.calculateTicksForOverallScatter(),
                    labels: {
                        formatter: function (val) {
                            let resp = val;
                            if (val && val.toFixed) {
                                resp = val.toFixed(0)
                            }
                            return resp;
                        }
                    }
                },
                yaxis: {
                    title: {
                        text: 'Winning Margin',
                        style: {
                            fontSize: '15px',
                            fontWeight: 'bold',
                            color: '#666'
                        }
                    },
                    min: 0
                },
                tooltip: {
                    custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                        const dataPoint = w.config.series[seriesIndex].data[dataPointIndex];
                        let dataObject = this.getFilteredOverallHurlingResults.find(it => it.id == dataPoint.id);
                        // Create custom HTML for the tooltip
                        return `
                            <div class="custom-tooltip" style="padding: 10px; border-radius: 2px; border: 1px solid #ddd;">
    <div style="display: flex; align-items: center; gap: 5px;">
                                <img src="/assets/gaa/teams/icons/${dataObject.homeTeam.code}.WEBP" 
                                    style="max-width: 30px; border: 1px solid;" 
                                    class="img_border" 
                                    alt="${dataObject.homeTeam.name} Logo" />
                                <span>${dataObject.homeTeam.name}</span> vs
                                <span>${dataObject.awayTeam.name}</span>
                                 <img src="/assets/gaa/teams/icons/${dataObject.awayTeam.code}.WEBP" 
                                    style="max-width: 30px; border: 1px solid;" 
                                    class="img_border" 
                                    alt="${dataObject.awayTeam.name} Logo" />
                                </div>
                                <div>
                                    Date: ${new Date(dataObject.date).toLocaleDateString()}
                                </div>
                                <div>
                                    Score: ${dataObject.homeGoals}:${dataObject.homePoints} - ${dataObject.awayGoals}:${dataObject.awayPoints}
                                </div>
                                <div>
                                    Margin: ${dataObject.winningMarginPoints}
                                </div>
                            </div>
                        `;
                    },
                },
                dataLabels: {
                    enabled: false,
                    formatter: function (value, { seriesIndex, dataPointIndex, w }) {
                        // Custom formatting for data point labels
                        return `Custom Label ${seriesIndex + 1}-${dataPointIndex + 1}: ${value}`;
                    }
                }
            }
        },
        //     let colours = [];
        //     if (this.overallTeam) {
        //         colours = [this.overallTeam.colour];
        //     }
        //     for (let i = 0; i < this.overallOpposition.length; i++) {
        //         colours.push(this.overallOpposition[i].colour);
        //     }
        //     colours.push('#808080');
        //     return {
        //         title: {
        //             text: 'Winning History',
        //             align: 'left',
        //             style: {
        //                 fontSize: '20px',
        //                 fontWeight: 'bold',
        //                 color: '#666'
        //             }
        //         },
        //         chart: {
        //             type: 'scatter',
        //             zoom: {
        //                 enabled: false
        //             }
        //         },
        //         colors: colours,
        //         markers: {
        //             strokeWidth: 1,
        //             strokeColor: '#000000'
        //         },
        //         xaxis: {
        //             title: {
        //                 text: 'Year'
        //             },
        //             type: 'numeric',
        //             // tickAmount: this.calculateTicksForOverallScatter(),
        //             labels: {
        //                 formatter: function (val) {
        //                     let resp = val;
        //                     if (val && val.toFixed) {
        //                         resp = val.toFixed(0)
        //                     }
        //                     return resp;
        //                 }
        //             }
        //         },
        //         yaxis: {
        //             title: {
        //                 text: 'Winning Margin',
        //                 style: {
        //                     fontSize: '15px',
        //                     fontWeight: 'bold',
        //                     color: '#666'
        //                 }
        //             },
        //             min: 0
        //         },
        //         dataLabels: {
        //             enabled: false,
        //             formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        //                 // Custom formatting for data point labels
        //                 return `Custom Label ${seriesIndex + 1}-${dataPointIndex + 1}: ${value}`;
        //             }
        //         }
        //     }
        // },
        overallScatterSeries() {
            let series = [];
            let team1 = { name: this.overallTeam ? this.overallTeam.name : 'Team', data: [] };
            let draw = { name: "Draw", data: [] };
            series.push(team1);
            for (let i = 0; i < this.getOverallOppositionFilter.length; i++) {
                if (this.getOverallOppositionFilter[i].grouper) continue;

                console.log(this.getOverallOppositionFilter[i]);
                let teamObj = this.getTeamsHurlingSort.find(it => it.id == this.getOverallOppositionFilter[i].id)
                let opposingTeam = { name: teamObj.name, data: [] };

                for (let j = 0; j < this.getFilteredOverallHurlingResults.length; j++) {
                    let currentResult = this.getFilteredOverallHurlingResults[j];
                    if (currentResult.homeTeam.id == this.overallTeam.id) {
                        if (currentResult.result == "HOME_WIN") {
                            if (opposingTeam.name == currentResult.awayTeam.name) {
                                team1.data.push({ "x": this.calculateYearPosition(currentResult), "y": currentResult.winningMarginPoints, "id": currentResult.id })
                            }
                        } else if (currentResult.result == "AWAY_WIN") {
                            if (opposingTeam.name == currentResult.awayTeam.name) {
                                opposingTeam.data.push({ "x": this.calculateYearPosition(currentResult), "y": currentResult.winningMarginPoints, "id": currentResult.id })
                            }
                        } else {
                            if (opposingTeam.name == currentResult.awayTeam.name) {
                                draw.data.push({ "x": this.calculateYearPosition(currentResult), "y": currentResult.winningMarginPoints, "id": currentResult.id });
                            }
                        }
                    } else if (currentResult.awayTeam.id == this.overallTeam.id) {
                        if (currentResult.result == "HOME_WIN") {
                            if (opposingTeam.name == currentResult.homeTeam.name) {
                                opposingTeam.data.push({ "x": this.calculateYearPosition(currentResult), "y": currentResult.winningMarginPoints, "id": currentResult.id })
                            }
                        } else if (currentResult.result == "AWAY_WIN") {
                            if (opposingTeam.name == currentResult.homeTeam.name) {
                                team1.data.push({ "x": this.calculateYearPosition(currentResult), "y": currentResult.winningMarginPoints, "id": currentResult.id })
                            }
                        } else {
                            if (opposingTeam.name == currentResult.homeTeam.name) {
                                draw.data.push({ "x": this.calculateYearPosition(currentResult), "y": currentResult.winningMarginPoints, "id": currentResult.id });
                            }
                        }
                    }
                }
                series.push(opposingTeam);
            }
            series.push(draw);
            return series;
        },
        overallYearFromFilters() {
            let years = ['All'];
            if (this.getFilteredOverallHurlingResults) {
                years.push(...this.getFilteredOverallHurlingResults.map(it => it.year).sort((a, b) => a - b));
            }
            return years;
        },
        overallYearToFilters() {
            let years = ['All'];
            if (this.getFilteredOverallHurlingResults) {
                years.push(...this.getFilteredOverallHurlingResults.map(it => it.year));
            }
            return years;
        }
    },
    methods: {
        ...mapActions(["fetchTeamsBySport", "fetchOverallResults", "updateOverallOppositionFilter", "updateOverallStadiumFilter", "updateOverallStageFilter",
            "updateOverallYearToFilter", "updateOverallYearFromFilter", "updateOveralldHomeFilter", "updateOverallRoundFilter"
        ]),
        updateOverallResults() {
            if (this.overallTeam) {
                this.fetchOverallResults({ "teamId": this.overallTeam.id, "competitionId": 2 });
            }
        },
        updateOverallFilter() {
            if (this.overallOpposition.find(it => it.name == 'Munster')) { // TODO - Missing half the counties here!
                let updatedTeams = [...this.overallOpposition, { id: 30 }, { id: 31 }, { id: 38 }, { id: 43 }, { id: 52 }, { id: 54 }];
                updatedTeams = updatedTeams.filter(it => it.id != this.overallTeam.id);
                this.updateOverallOppositionFilter(updatedTeams);
            } else if (this.overallOpposition.find(it => it.name == 'Leinster')) {
                let updatedTeams = [...this.overallOpposition, { id: 28 }, { id: 35 }, { id: 40 }, { id: 41 }, { id: 49 }, { id: 56 }]
                updatedTeams = updatedTeams.filter(it => it.id != this.overallTeam.id);
                this.updateOverallOppositionFilter(updatedTeams);
            } else if (this.overallOpposition.find(it => it.name == 'Ulster')) {
                let updatedTeams = [...this.overallOpposition, { id: 26 }]
                updatedTeams = updatedTeams.filter(it => it.id != this.overallTeam.id);
                this.updateOverallOppositionFilter(updatedTeams);
            } else if (this.overallOpposition.find(it => it.name == 'Connacht')) {
                let updatedTeams = [...this.overallOpposition, { id: 37 }, { id: 55 }]
                updatedTeams = updatedTeams.filter(it => it.id != this.overallTeam.id);
                this.updateOverallOppositionFilter(updatedTeams);
            } else {
                if (this.overallOpposition && this.overallOpposition.length == 0) {
                    this.updateOverallOppositionFilter(null);
                } else {
                    this.updateOverallOppositionFilter(this.overallOpposition);
                }
            }
            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    opposition: this.overallOpposition.map(team => team.id ? team.id : team.name)
                }
            });
        },
        overallStageFilterChanged() {
            this.updateOverallStageFilter(this.overallStageFilter);
        },
        overallRoundFilterChanged() {
            this.updateOverallRoundFilter(this.overallRoundFilter);
        },
        overallYearFromFilterChanged() {
            this.updateOverallYearFromFilter(this.overallYearFromFilter);
        },
        overallYearToFilterChanged() {
            this.updateOverallYearToFilter(this.overallYearToFilter);
        },
        overallHomeFilterChanged() {
            this.updateOveralldHomeFilter(this.overallHomeFilter);
        },
        overallStadiumFilterChanged() {
            this.updateOverallStadiumFilter(this.overallStadiumFilter);
        },
        calculateYearPosition(item) {
            const [year, month, day] = item.date;

            // Create a Date object for the start of the year and the given date
            const startOfYear = new Date(year, 0, 1);
            const currentDate = new Date(year, month - 1, day);

            // Calculate the number of days between the start of the year and the given date
            const dayOfYear = Math.floor((currentDate - startOfYear) / (1000 * 60 * 60 * 24)) + 1;

            // Check if the year is a leap year (366 days) or not (365 days)
            const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
            const daysInYear = isLeapYear ? 366 : 365;

            // Calculate the decimal representation
            const decimalYear = year + (dayOfYear - 1) / daysInYear;

            return decimalYear;
        },
        populateQueryParams() {
            if (this.$router.currentRoute.query.team) {
                let queryString = this.$router.currentRoute.query.team;
                this.overallTeam = this.getTeamsHurlingSort.find(it => it.id = queryString)
                this.updateOverallResults();
            }
            if (this.$router.currentRoute.query.from) {
                this.overallWinnerYearFromFilter = this.$router.currentRoute.query.from;
                this.overallWinnerYearFromFilterChanged();
            }
            if (this.$router.currentRoute.query.to) {
                this.overallWinnerYearToFilter = this.$router.currentRoute.query.to;
                this.overallWinnerYearToFilterChanged();
            }
        }
    }
}
</script>

<style>
.chart_height {
    max-height: 400px;
    overflow: hidden;
}

.chart_height_mobile {
    max-height: 400px;
    overflow: hidden;
}

.fade-enter-active,
.fade-leave-active {
    transition: max-height 0.5s ease-in;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
    {
    max-height: 0;
}

.chartBackground {
    background-image: url('/assets/logo/ktg_green_fade.svg');
    background-size: contain;
    background-position: center;
}
</style>