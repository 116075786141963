<template>
    <div>
       <v-row>
            <v-col>
                <v-tabs
                    v-model="tab"
                    grow
                    show-arrows
                    >
                    <v-tab
                        v-for="item in items"
                        :key="item.value" @click="handleTabInput(item)"
                    >
                        {{ item.text }}
                    </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab" touchless>
                    <v-tab-item>
                        <head-to-head-table/>
                    </v-tab-item>
                    <v-tab-item>
                        <share-of-wins/>
                    </v-tab-item>
                    <v-tab-item>
                        <win-distribution/>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
       </v-row> 
    </div>
</template>

<script>
import WinDistribution from '@/components/stats/headToHead/WinDistribution.vue'
import HeadToHeadTable from '@/components/stats/headToHead/HeadToHeadTable.vue'
import ShareOfWins from '@/components/stats/headToHead/ShareOfWins.vue'
import TeamHonours from '../../../components/stats/honours/TeamHonours.vue'
import WinsOverTime from '@/components/stats/honours/WinsOverTime'
import { mapActions } from 'vuex/dist/vuex.common.js'
export default {
  components: { HeadToHeadTable, ShareOfWins, WinDistribution, WinsOverTime, TeamHonours },
    props: ['mode', 'teamId'],
    mounted(){
        if(this.mode){
            this.tab = Number(this.mode);
        }
    },
    data(){
        return {
            tab: null,
            items: [
            { text: 'Result Table', value: 0}, { text: 'Pie Chart', value: 1}, { text: 'Scatter Graph', value: 2}
            ],
        }
    },
    methods: {
        ...mapActions(['updateOverallTeamSelections', 'resetResultFilterOptions', 'resetWinnerFilterOptions']),
        handleTabInput(item) {
            this.updateOverallTeamSelections(null);
            this.resetResultFilterOptions();
            this.resetWinnerFilterOptions();
            this.updateOverallTeamSelections([]);
            this.$router.push({
                name: `HeadToHead_${this.$router.currentRoute.meta.sport}`,
                params: { mode: item.value, teamId: null }
            });
        },
    }
}
</script>

<style>

</style>