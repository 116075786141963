<template>
    <div>
        <v-overlay :value="loading" :opacity="0.35" color="#d3d3d3">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'" class="d-flex align-center">
                                <div style="word-break: keep-all;">
                                    <h5>Share of head to head wins</h5>
                                </div>
                            </v-col>
                            <v-spacer />
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                <div>
                                    <v-select v-if="$vuetify.breakpoint.xsOnly" :items="getTeamsHurlingSort" v-model="headToHeadAway"
                                        @change="updateHeadToHeadResults" label="Team 1" item-text="name" return-object
                                        outlined hide-details hide-no-data></v-select>
                                    <v-combobox v-else :items="getTeamsHurlingSort" v-model="headToHeadAway"
                                        @change="updateHeadToHeadResults" label="Team 1" item-text="name" return-object
                                        outlined hide-details hide-no-data></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                <div>
                                    <v-select v-if="$vuetify.breakpoint.xsOnly" :items="headToHeadPieChartTeamOption" v-model="headToHeadHome"
                                        @change="updateHeadToHeadResults" label="Team 2" item-text="name" return-object
                                        outlined hide-details hide-no-data></v-select>
                                    <v-combobox v-else :items="headToHeadPieChartTeamOption" v-model="headToHeadHome"
                                        @change="updateHeadToHeadResults" label="Team 2" item-text="name" return-object
                                        outlined hide-details hide-no-data></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                <div>
                                    <v-select v-if="$vuetify.breakpoint.xsOnly" :items="headToHeadStageOptions" v-model="headToHeadStageFilter"
                                        @change="headToHeadStageFilterChanged" item-text="label" item-value="value"
                                        label="Stage" outlined hide-details hide-no-data></v-select>
                                    <v-combobox v-else :items="headToHeadStageOptions" v-model="headToHeadStageFilter"
                                        @change="headToHeadStageFilterChanged" item-text="label" item-value="value"
                                        label="Stage" outlined hide-details hide-no-data></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'" class="d-flex align-center">
                                <div>
                                    <v-btn class="grey" :class="this.$vuetify.theme.dark ? 'darken-2' : 'lighten-2'"
                                        @click="showHeadToHeadFilters = !showHeadToHeadFilters">{{ showHeadToHeadFilters
                                        ? 'Less' : 'More' }}
                                        <v-icon class="ml-1">{{ showHeadToHeadFilters ?
                                            'mdi-arrow-up-drop-circle-outline' : 'mdi-arrow-down-drop-circle-outline'
                                            }}</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <transition name="fade">
                            <v-row v-if="showHeadToHeadFilters"
                                :class="this.$vuetify.breakpoint.xs ? 'settings_height_mobile' : 'settings_height'">
                                <v-spacer />
                                <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                    <div>
                                        <v-combobox :items="headToHeadYearFromFilters"
                                            v-model="headToHeadYearFromFilter" @change="headToHeadYearFromFilterChanged"
                                            label="Year from" outlined hide-details hide-no-data></v-combobox>
                                    </div>
                                </v-col>
                                <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                    <div>
                                        <v-combobox :items="headToHeadYearToFilters" v-model="headToHeadYearToFilter"
                                            @change="headToHeadYearToFilterChanged" label="Year to" outlined
                                            hide-details hide-no-data></v-combobox>
                                    </div>
                                </v-col>
                                <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                    <div>
                                        <v-combobox :items="headToHeadHomeAwayOptions" v-model="headToHeadFilter"
                                            @change="updateHeadToHeadFilter" item-text="label" return-object
                                            label="Location" outlined hide-details hide-no-data></v-combobox>
                                    </div>
                                </v-col>
                                <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                    <div>
                                        <v-combobox :items="getFilteredStadiums" v-model="headToHeadStadiumFilter"
                                            @change="headToHeadStadiumFilterChanged" item-text="label"
                                            item-value="value" label="Stadium" outlined hide-details
                                            hide-no-data></v-combobox>
                                    </div>
                                </v-col>
                                <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                    <div>
                                        <v-combobox :items="getHeadToHeadRoundOptions" @change="headToHeadRoundFilterChanged"
                                            v-model="headToHeadRoundFilter" item-text="label" item-value="value"
                                            label="Round" outlined hide-details hide-no-data></v-combobox>
                                    </div>
                                </v-col>
                            </v-row>
                        </transition>
                    </v-card-title>
                    <v-card-text>
                        <div id="pie-chart-share">
                            <div id="head-to-pie-chart">
                                <apexchart type="pie" height="400" :options="headToHeadPieChartOptions"
                                    :series="headToHeadPieChartTotals" />
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex/dist/vuex.common.js';
import StatsCard from '@/components/card/statsCard/StatsCard'

export default {
    name: 'RollOfHonour',
    components: {
        StatsCard
    },
    async mounted() {
        if (this.getTeamsHurlingSort.length > 0) {
            this.loading = false;
        } else {
            let resp = await this.fetchTeamsBySport(this.$router.currentRoute.meta.sport.toUpperCase());
            this.loading = false;
        }
        this.populateQueryParams();
    },
    data() {
        return {
            loading: true,
            headToHeadHome: null,
            headToHeadAway: null,
            headToHeadFilter: { "label": 'All', "value": null },
            headToHeadYearFromFilter: 'All',
            headToHeadYearToFilter: 'All',
            headToHeadStadiumFilter: 'All',
            headToHeadStageFilter: 'All',
            headToHeadRoundFilter: 'All',
            showHeadToHeadFilters: false
        }
    },
    computed: {
        ...mapGetters(["getTeamsHurlingSort", "getResults", "getFilteredStadiums", "getFilteredHurlingResults", "getHeadToHeadRoundOptions"]),
        headToHeadPieChartTeamOption() {
            if (this.headToHeadAway) {
                return this.$store.getters['getTeamsHurlingSort'].filter(it => it.id != this.headToHeadAway.id)
            }
            return this.$store.getters['getTeamsHurlingSort'];
        },
        headToHeadHomeAwayOptions() {
            let teamOptions = [{ "label": 'All', "value": null }, { "label": 'Neutral', "value": -1 }];
            if (this.headToHeadAway) {
                teamOptions.push({ "label": this.headToHeadAway.name, "value": this.headToHeadAway.id });
            }
            if (this.headToHeadHome) {
                teamOptions.push({ "label": this.headToHeadHome.name, "value": this.headToHeadHome.id });
            }
            return teamOptions;
        },
        headToHeadYearToFilters() {
            let years = ['All'];
            years.push(...this.getResults.map(it => it.year));
            return years;
        },
        headToHeadYearFromFilters() {
            let years = ['All'];
            years.push(...this.getResults.map(it => it.year).sort((a, b) => a - b));
            return years;
        },
        headToHeadStageOptions() {
            let stages = ['All'];
            stages.push(...this.$store.getters['getResults'].map(it => it.stage));
            return stages;
        },
        headToHeadPieChartOptions() {
            return {
                title: {
                    text: 'Win Percentage',
                    align: 'left',
                    style: {
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: '#666'
                    }
                },
                subtitle: {
                    text: 'Know The Game',
                    align: 'left',
                    style: {
                        fontSize: '10px',
                        fontWeight: 'bold',
                        color: '#666'
                    }
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        color: '#000'
                    }
                },
                chart: {
                    type: 'pie',
                },
                fill: {
                    type: 'image',
                    opacity: 0.85,
                    image: {
                        src: this.headToHeadPieChartImages,
                        width: 400,
                        height: 400
                    },
                },
                export: {
                    enabled: true,
                    // Include download options in the menu
                    menu: {
                        download: ['png', 'svg']
                    }
                },
                colors: this.headToHeadPieChartColours,
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        startAngle: 0,
                    }
                },
                labels: this.headToHeadPieChartLabels,
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            }
        },
        headToHeadPieChartTotals() {
            let resultTotals = this.$store.getters['getResultHurlingTotals'];
            let charTotals = [0, 0, 1];
            if (this.headToHeadHome && !isNaN(resultTotals[this.headToHeadHome.id]) && this.headToHeadAway && !isNaN(resultTotals[this.headToHeadAway.id])) {
                charTotals = [resultTotals[this.headToHeadHome.id], resultTotals[this.headToHeadAway.id], resultTotals['draw']]
            }

            return charTotals;
        },
        headToHeadPieChartImages() {
            let images = ['', '', '/assets/gaa/teams/chart_icons/draw.jpg'];
            if (this.headToHeadHome) {
                images[0] = `/assets/gaa/teams/icons/${this.headToHeadHome.code}.WEBP`;
            }
            if (this.headToHeadAway) {
                images[1] = `/assets/gaa/teams/icons/${this.headToHeadAway.code}.WEBP`;
            }
            return images;
        },
        headToHeadPieChartColours() {
            let colours = ['#888888', '#949494', "#808080"];
            if (this.headToHeadHome) {
                colours[0] = this.headToHeadHome.colour;
            }
            if (this.headToHeadAway) {
                colours[1] = this.headToHeadAway.colour;
            }
            return colours;
        },
        headToHeadPieChartLabels() {
            let labels = ['Team 2', 'Team 1'];
            if (this.headToHeadHome) {
                labels[0] = this.headToHeadHome.name + ' ' + this.headToHeadScatterSeries[0].data.length;
            }
            if (this.headToHeadAway) {
                labels[1] = this.headToHeadAway.name + ' ' + this.headToHeadScatterSeries[1].data.length;
            }
            if (this.headToHeadHome && this.headToHeadAway) {
                if (this.headToHeadScatterSeries[0].data.length > 0 || this.headToHeadScatterSeries[1].data.length > 0 || this.headToHeadScatterSeries[2].data.length > 0) {
                    labels.push('Draw' + ' ' + this.headToHeadScatterSeries[2].data.length);
                } else {
                    labels.push('Never met');
                }
            } else {
                labels.push('Never met');
            }

            return labels;
        },
        headToHeadScatterSeries() {
            let series = [];
            let team1 = { name: this.headToHeadAway ? this.headToHeadAway.name : 'Team 1', data: [] };
            let team2 = { name: this.headToHeadHome ? this.headToHeadHome.name : 'Team 2', data: [] };
            let draw = { name: "Draw", data: [] };
            for (let i = 0; i < this.getFilteredHurlingResults.length; i++) {
                let currentResult = this.getFilteredHurlingResults[i];
                if (currentResult.homeTeam.id == this.headToHeadHome.id) {
                    if (currentResult.result == "HOME_WIN") {
                        team2.data.push({ "x": currentResult.year, "y": currentResult.winningMarginPoints })
                    } else if (currentResult.result == "AWAY_WIN") {
                        team1.data.push({ "x": currentResult.year, "y": currentResult.winningMarginPoints })
                    } else {
                        draw.data.push({ "x": currentResult.year, "y": currentResult.winningMarginPoints });
                    }
                } else {
                    if (currentResult.result == "HOME_WIN") {
                        team1.data.push({ "x": currentResult.year, "y": currentResult.winningMarginPoints })
                    } else if (currentResult.result == "AWAY_WIN") {
                        team2.data.push({ "x": currentResult.year, "y": currentResult.winningMarginPoints })
                    } else {
                        draw.data.push({ "x": currentResult.year, "y": currentResult.winningMarginPoints });
                    }
                }
            }
            series.push(team2);
            series.push(team1);
            series.push(draw);
            return series;
        },
        headToHeadStageOptions() {
            let stages = ['All'];
            stages.push(...this.$store.getters['getResults'].map(it => it.stage));
            return stages;
        },
    },
    methods: {
        ...mapActions(["fetchTeamsBySport", "updateHeadToHeadHomeFilter", "fetchHeadToHeadResults", "updateHeadToHeadYearFromFilter", "updateHeadToHeadYearToFilter",
            "updateHeadToHeadStadiumFilter", "updateHeadToHeadStageFilter", "updateHeadToHeadRoundFilter"
        ]),
        updateHeadToHeadResults() {
            if (this.headToHeadHome && this.headToHeadAway) {
                this.fetchHeadToHeadResults({ "team1Id": this.headToHeadHome.id, "team2Id": this.headToHeadAway.id, "competitionId": 2 });
            }
            if (!((this.headToHeadHome && this.headToHeadFilter.value == this.headToHeadHome.id) || (this.headToHeadAway && this.headToHeadFilter.value == this.headToHeadAway.id))) {
                this.headToHeadFilter = { "label": 'All', "value": null };
                this.updateHeadToHeadFilter();
            }
        },
        updateHeadToHeadResults() {
            if (this.headToHeadHome && this.headToHeadAway) {
                this.fetchHeadToHeadResults({ "team1Id": this.headToHeadHome.id, "team2Id": this.headToHeadAway.id, "competitionId": 2 });
            }
            if (!((this.headToHeadHome && this.headToHeadFilter.value == this.headToHeadHome.id) || (this.headToHeadAway && this.headToHeadFilter.value == this.headToHeadAway.id))) {
                this.headToHeadFilter = { "label": 'All', "value": null };
                this.updateHeadToHeadFilter();
            }
        },
        updateHeadToHeadFilter() {
            this.updateHeadToHeadHomeFilter(this.headToHeadFilter)
        },
        headToHeadYearFromFilterChanged() {
            this.updateHeadToHeadYearFromFilter(this.headToHeadYearFromFilter);
        },
        headToHeadYearToFilterChanged() {
            this.updateHeadToHeadYearToFilter(this.headToHeadYearToFilter);
        },
        headToHeadStadiumFilterChanged() {
            this.updateHeadToHeadStadiumFilter(this.headToHeadStadiumFilter);
        },
        headToHeadStageFilterChanged() {
            this.updateHeadToHeadStageFilter(this.headToHeadStageFilter);
        },
        headToHeadRoundFilterChanged() {
            this.updateHeadToHeadRoundFilter(this.headToHeadRoundFilter);
        },
        populateQueryParams() {
            if (this.$router.currentRoute.query.competition) {
                let queryString = this.$router.currentRoute.query.competition;
                this.overallCompetitionFilter = queryString.split(' ') // Split the string into an array of words
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
                    .join(' ');
                this.overallCompetitionFilterChanged();
            }
            if (this.$router.currentRoute.query.from) {
                this.overallWinnerYearFromFilter = this.$router.currentRoute.query.from;
                this.overallWinnerYearFromFilterChanged();
            }
            if (this.$router.currentRoute.query.to) {
                this.overallWinnerYearToFilter = this.$router.currentRoute.query.to;
                this.overallWinnerYearToFilterChanged();
            }
        }
    }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: max-height 0.5s ease-in;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
    {
    max-height: 0;
}
</style>